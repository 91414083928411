@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #F5F5F5;
  background-image: url('/src/assets/images/extra/bg.gif');
  background-repeat: repeat;
  background-size: contain;
}

.typed-text {
  position: relative;
  color: #f0a718;
  white-space: nowrap;
  font-weight: bold;
}

.typed-text:hover::before {
  content: attr(datatype);
  position: absolute;
  width: 0;
  color: #0099DD;
  font-weight: bold;
  border-right: 3px solid #0099DD;
  overflow: hidden;
  animation: textAnimation 1s linear infinite;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Customize Scrollbars for a Professional Look */
body::-webkit-scrollbar {
  width: 3px; /* Adjust the scrollbar width */
  height: 3px; /* Adjust the scrollbar height */
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(#ffffff, #0099DD);
  border-radius: 8px; /* Create a rounded appearance */
}
body.scroll-top::-webkit-scrollbar-thumb {
  background: linear-gradient(#ffffff, #0099DD);
  border-radius: 8px; /* Create a rounded appearance */
}
body.scroll-bottom::-webkit-scrollbar-thumb {
  background: linear-gradient( #0099DD, #ffffff);
  border-radius: 8px; /* Create a rounded appearance */
}


@keyframes moveRightToLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% + 300px));
    /* Move by 100% of parent's width */
  }
}

.moveRightToLeft {
  position: relative;
  transition: transform 2s ease-in-out;
  width: 100%;
  /* Ensure the element takes up the full width of its parent */
}

.parent-div:hover .moveRightToLeft {
  animation: moveRightToLeft 2s linear;
  transform: translateX(calc(-100% + 300px));
  /* Move by 100% of parent's width when hovered */
}

@keyframes textAnimation {

  0%,
  10%,
  100% {
    width: 0;
  }

  70%,
  90% {
    width: 100%;
  }
}

@keyframes scroll-infinite {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-70%);
  }
}

.scroll-infinite {
  animation: scroll-infinite 50s linear infinite;
}

@keyframes scroll-logo-l2r {
  0% {
    transform: translateX(100%);
    opacity: 100;
  }

  100% {
    transform: translateX(-70%);
    opacity: 0;
  }
}

.scroll-logo-l2r {
  animation: scroll-logo-l2r 5s linear infinite;
}

@keyframes scroll-logo-r2l {
  0% {
    transform: translateX(30%);
    opacity: 100;
  }

  100% {
    transform: translateX(150%);
    opacity: 0;
  }
}

.scroll-logo-r2l {
  animation: scroll-logo-r2l 5s linear infinite;
}


@keyframes bounce {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.bounce-infinite {
  animation: bounce 3s linear infinite;
}